import api from '@/libs/axios'

export default {
    async getList(params) {
        let response = await api.get('/admin/admin-users', { params })
        return response
    },
    async create(payload) {
        const response = await api.post('/admin/admin-users', payload)
        return response.data.data.new_document || null
    },
    async update(payload) {
      const response = await api.put('/admin/admin-users', payload)
      return response
    },
    async delete(payload) {
      const response = await api.delete('/admin/admin-users', {
        data: payload,
      })
      return response
    },
    async updateLanguage(payload) {
        const response = await api.put('/admin/admin-users/language', payload)
        return response
    },
    async updateRole(payload) {
      const response = await api.put('/admin/admin-users/update-role', payload)
      return response
    },
    getRoles() {
      return {
        'admin' : 'Admin',
        'customer_policy' : 'CSKH',
        'community' : 'Community',
        'coordinator': 'Điều phối',
        'business' : 'Nghiệp vụ',
        'collaborators' : 'CTV',
      }
    }
}
