import api from '@/libs/axios'

export default {
    async getList(params) {
        let response = await api.get('/admin/admin-roles', { params })
        return response
    },
    async create(payload) {
        const response = await api.post('/admin/admin-roles', payload)
        return response.data.data.new_document || null
    },
    async update(payload) {
        const response = await api.put('/admin/admin-roles', payload)
        return response
    },
    async delete(payload) {
      const response = await api.delete('/admin/admin-roles', {
        data: payload,
      })
      return response
    },
    async getPrivileges(params) {
      let response = await api.get('/admin/admin-roles/privileges', { params })
      return response.data;
    },
    async updatePrivileges(payload) {
      const response = await api.post('/admin/admin-roles/privileges', payload)
      return response.data;
    },
}
