import api from '@/libs/axios'

export default {
    async getList(params) {
        let response = await api.get('/admin/admin-privileges', { params })
        return response
    },
    async create(payload) {
        const response = await api.post('/admin/admin-privileges', payload)
        return response.data.data.new_document || null
    },
    async update(payload) {
        const response = await api.put('/admin/admin-privileges', payload)
        return response
    },
    async delete(payload) {
      const response = await api.delete('/admin/admin-privileges', {
        data: payload,
      })
      return response
    },
}
